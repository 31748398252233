import { usePageContext } from "@ignite/react/context/pageContext"
import Box from "@material-ui/core/Box"
import { createStyles, makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import React from "react"
import { Helmet } from "react-helmet-async"

import Spinner from "../Spinner"
import { PageRenderer } from "./ContentRenderer"

export const isItemComponent = (entry: any) => {
  if (entry.type === "JournalPage") {
    //TODO: Fix this in the future please.
    // Temporary hack for journal pages, BE is sending items as children with type,
    // making the data into components instead of raw JSON data
    return false
  }

  return (
    entry &&
    typeof entry === "object" &&
    entry.type &&
    (entry.type.endsWith("Block") || entry.type.endsWith("Page"))
  )
}

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    root: {
      minHeight: "70vh",
    },
    menuOpen: {},
    "@global": {
      body: {
        "& .fade:after": {
          content: "''",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: palette.backdrop.main,
          transition: "opacity 500ms cubic-bezier(0.4, 0.0, 0.2, 1)",
          opacity: 0,
          zIndex: 2,
          pointerEvents: "none",
        },
        "&.menuOpen": {
          "& .fade:after": {
            opacity: 1,
          },
        },
      },
    },
  })
)

const ContentProvider: React.FC = () => {
  const {
    state: { page },
  } = usePageContext()

  const classes = useStyles()

  return (
    <Box className={clsx(classes.root, "fade")}>
      {(page.loading && <Spinner />) ||
        (page.error && (
          <PageRenderer
            fallback={<Spinner />}
            content={{
              id: "error",
              type: "ErrorPage",
              properties: Object.assign(
                {
                  title: "Error",
                },
                page.error
              ),
            }}
          />
        )) ||
        (page && page.data && (
          <>
            <Helmet>
              <title>{page.data.meta.title}</title>
              {page.data.meta.metaDescription && (
                <meta
                  name="description"
                  content={page.data.meta.metaDescription}
                />
              )}
              {page.data.type !== "SearchResultsPage" ? (
                page.data.meta?.canonicalUrl &&
                page.data.meta?.canonicalUrl !== "" ? (
                  <link rel="canonical" href={page.data.meta?.canonicalUrl} />
                ) : (
                  <link rel="canonical" href={page.data.url} />
                )
              ) : null}
            </Helmet>
            <PageRenderer content={page.data} />
          </>
        )) ||
        (null as any)}
    </Box>
  )
}

export default ContentProvider
